import React, { Fragment, useEffect, useState } from 'react';
import { Tabs, Tab, Button, Grid, Box, Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider, Rating, Modal, ListItemButton, FormHelperText } from '@mui/material';
import config from '../core/config';



// spec
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import HandymanIcon from '@mui/icons-material/Handyman';
import Person4Icon from '@mui/icons-material/Person4';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


import CarTile from '../screens/Car/CarTile';
import CarSpec from '../screens/Car/CarSpec';
import SidePanel from '../components/SlidePanel';
import CarCarousel from '../screens/Car/CarCarousel';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HiveIcon from '@mui/icons-material/Hive';
import Column from '../components/Column';

import axios from 'axios';
import AppLoader from '../components/AppLoader';
import WebFooter from '../components/WebFooter';

import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';





const Home = (props) => {
    const authData = useSelector(state => state.auth);
    const history = useHistory();
    const [visibleItemTile, setVisibleItemTile] = useState(false);
    const [rating, setRating] = React.useState(0);
    const [tabValue, setTabValue] = React.useState(0);
    const [searchText, setSearchText] = React.useState("");
    const [callModalOpen, setCallModalOpen] = React.useState(false);
    const [wpModalOpen, setWpModalOpen] = React.useState(false);
    const [priceModalOpen, setPriceModalOpen] = React.useState(false);
    const [isWebsite, setIsWebsite] = useState(false);

    const [visibleCars , setVisibleCars] = useState([]);

    // const [pageCount, setPageCount] = useState(2);
    const [homeData, setHomeData] = useState({
        cars: 0,
        trucks: 0,
        others: 0,
    });
    const [rowCount, setRowCount] = useState(5);
    const [rawData, setRawData] = useState(null);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchHomData = async () => {
        setIsLoading(true);
        await axios.get(`${config.rootUrl}/${config.uris.homedata}`)
        .then(({ data }) => {
            if (data.status) {
                setHomeData(data.data);
            } 
            setIsLoading(false);
        }).catch(err => {
            console.error(err);
            setIsLoading(false);
        });
        
    }
    
    const fetchProducts = async ( val = 0, page = 1) => {
        setIsLoading(true);
        var token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        // await axios.get(`${config.rootUrl}/${config.uris.getAllProducts}`)
        await axios.get(`${config.rootUrl}/${config.uris.getAllProducts}?count=${rowCount}&cat=${val+4}&page=${page}`)
        // await axios.get(`${config.rootUrl}/${config.uris.getAllProducts}?count=${rowCount}&cat=${val+1}&page=${page}`)
        // await axios.get(`${config.rootUrl}/${config.uris.getAllProducts}?cat=${val+1}&page=${lastPage}`)
        .then(({ data }) => {
            if (data.status) {
                setRawData(data.data);
                // if(products.length == 0) {
                //     setProducts(data.data.data);
                // } else {
                    setProducts(prev => [...prev, ...data.data.data]);
                // }
            } 
            setIsLoading(false);
        }).catch(err => {
            console.error(err);
            setIsLoading(false);
        });
        
    }

    const increaseViews = async(id = null) => {
        var myUri = '';
        if(authData.isAuth){
            var token = localStorage.getItem('token');
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            myUri = `${config.rootUrl}/auth/${config.uris.triggreViews}`;
        } else {
            myUri = `${config.rootUrl}/${config.uris.triggreViews}`;

        }
        await axios.post(myUri, {
           product_id: id 
        })
        .then(({ data }) => {
            if (data.status) {
                console.log(data);
            } 
        }).catch(err => {
            console.error(err);
        });
    }
    
    const handleCallModalOpen = () => setCallModalOpen(true);
    const handleCallModalClose = () => setCallModalOpen(false);

    const handleWpModalOpen = () => setWpModalOpen(true);
    const handleWpModalClose = () => setWpModalOpen(false);
    
    const handlePriceModalOpen = () => {
        authData.isAuth 
        ? setPriceModalOpen(true)
        : history.push('/login');
    };
    const handlePriceModalClose = () => setPriceModalOpen(false);

    const handleChangeTab = (event, newValue) => {
        setProducts([]);
        fetchProducts(newValue);
        setTabValue(newValue);
    };

    const handleSidePanelClose = () => {
        setSelectedProduct(null);
        setVisibleItemTile(false);
    }

    

    const carSpecsList = [
        { title: selectedProduct != null && selectedProduct.registration_authority, subtitle: "Regstration Authority", icon: <PersonPinCircleIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
        { title: selectedProduct != null && selectedProduct.manufacture_date, subtitle: "Manufacturer Date", icon: <HandymanIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
        { title: selectedProduct != null && selectedProduct.vechile_location, subtitle: "Vechile Location", icon: <LocationOnIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
        { title: selectedProduct != null && selectedProduct.insurance, subtitle: "Insurance", icon: <CreditScoreIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
        { title: selectedProduct != null && selectedProduct.kilometre_drive, subtitle: "KM Drive", icon: <TimeToLeaveIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
        { title: selectedProduct != null && selectedProduct.fitness, subtitle: "Fitness", icon: <FitnessCenterIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
        { title: selectedProduct != null && selectedProduct.rc_or_ca, subtitle: "RC/CA", icon: <HandymanIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
        { title: selectedProduct != null && selectedProduct.year, subtitle: "Year", icon: <AccessTimeIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
        { title: selectedProduct != null && selectedProduct.tax, subtitle: "Tax", icon: <PriceCheckIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
        { title: selectedProduct != null && selectedProduct.hp, subtitle: "HP", icon: <PriceCheckIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
        { title: selectedProduct != null && selectedProduct.owner, subtitle: "Owner", icon: <PriceCheckIcon sx={{ color: config.colors.primary.main, fontSize: "14px" }} /> },
    ];





    
    useEffect(() => {
        if(window.screen.width> 1000) {
            console.log('Large Screen');
            setRowCount(10);
        }


        fetchHomData();
        fetchProducts();
        // document.addEventListener('scroll', trackScrolling);
        var temp = products.filter((item)=> {
            var searchKey = item.name + item.price + item.price+item.year
            searchKey = searchKey.toLowerCase()
            return searchKey.indexOf(searchText.toLowerCase()) !== -1
        });
        if(window.screen.width> 1000) {
            setIsWebsite(true);
        } else{
            setIsWebsite(false);
        }

        // setVisibleCars(temp)
    },[searchText])
    
    return (
        <div>
            <div style={{ marginTop: "63px", position: "fixed", top: "2px", width: "100%",height: "108px", zIndex: "800" }}>
                <div style={{
                    // background: "linear-gradient(180deg, rgb(0, 31, 111), #003e83, rgb(0, 165, 237))",
                    background: config.colors.primary.main,
                    padding: "8px 8px 0px 8px",
                    // height: "180px",
                    // borderBottomLeftRadius: "20px",
                    // borderBottomRightRadius: "20px",
                }}>
                    <Column>
                        {/* <div style={{ padding: "2px 6px" }}>
                            <h3 style={{
                                fontSize: "19px",
                                fontWeight: "400",
                                color: "#fff",
                                margin: "0px",
                                marginTop: "2px",
                            }}>
                                Find the best car
                            </h3>
                                    <h1 style={{
                                        fontSize: "25px",
                                        fontWeight: "400",
                                        color: config.colors.accent.main,
                                        margin: "0px",
                                        marginTop: "2px",
                                        marginBottom: "5px  ",
                                    }}>
                                        Smartly
                                    </h1>
                        </div> */}
                        <Grid container justifyContent="center" spacing={0}>
                            <Grid item xs={10} sm={10} md={4} lg={4}>
                                <input 
                                    onClick={()=>props.history.push('/search')}
                                    onChange={(e)=>{setSearchText(e.target.value)}}
                                    value = {searchText}
                                    type="text"
                                    readOnly
                                    className="form-control app-input"
                                    placeholder="Search ..." />
                            </Grid>
                            <Grid item xs={2} sm={2} md={1} lg={1}>
                                <Button size='large' variant="contained" color="secondary" sx={{ marginLeft: "-2px", marginTop: "5px", width: "98%", minWidth: "57px", height: "40px", color: "#fff", borderRadius: "12px" }}>
                                    <SearchIcon />
                                </Button>
                            </Grid>
                        </Grid>
                        <Box sx={{ width: '100%', marginTop: "-5px" }}>
                            <Tabs value={tabValue} onChange={handleChangeTab} centered
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                            >
                                <Tab sx={{ color: "white", padding: "5px", minHeight: "50px" }} iconPosition="start" icon={<DirectionsCarFilledIcon sx={{ fontSize: "15px" }} />} label={`All Cars(${homeData.all})`} />
                                {/* <Tab sx={{ color: "white", padding: "5px", minHeight: "50px" }} iconPosition="start" icon={<DirectionsCarFilledIcon sx={{ fontSize: "15px" }} />} label={`Cars(${homeData.cars})`} />
                                <Tab sx={{ color: "white", padding: "5px", minHeight: "50px" }} iconPosition="start" icon={<LocalShippingIcon sx={{ fontSize: "15px" }} />} label={`Trucks(${homeData.trucks})`} />
                                <Tab sx={{ color: "white", padding: "5px", minHeight: "50px" }} iconPosition="start" icon={<HiveIcon sx={{ fontSize: "15px" }} />} label={`Others(${homeData.others})`} /> */}
                            </Tabs>
                        </Box>
                    </Column>
                </div>
            </div>
            <div id="popularCars">
                {/* <Typography variant='h6' component="div" sx={{ fontWeight: "500", fontSize: "20px", marginBottom: "5px" }}>
                    Popular Cars
                </Typography> */}
                    {/* {
                        prod
                    } */}
                    <InfiniteScroll
                        dataLength={products.length}
                        next={() => {
                            fetchProducts(tabValue, rawData.current_page+1);
                        }}
                        hasMore={true}
                        loader={<AppLoader visibal={isLoading} />}
                    >
                        <Grid container spacing={0.9}>
                        {products.map((item, i) => (
                            <Grid key={i} item xs={12} sm={12} md={4} lg={3} >
                                <CarTile data={item} onpress={() => { 
                                    setSelectedProduct(item);
                                    setVisibleItemTile(true);
                                    increaseViews(item.id);
                                    // handleItemTileAction(item)
                                }} />
                            </Grid>
                        ))}
                        </Grid>
                    </InfiniteScroll>
                {/* <Grid container spacing={0.5}>
                    {
                        Object.keys(products).length > 0 ?
                        products.map((item, i) => {
                            return (
                                <Grid key={i} item xs={12} sm={12} md={4} lg={3} >
                                    <CarTile data={item} onpress={() => { 
                                        setSelectedProduct(item);
                                        setVisibleItemTile(true);
                                        // handleItemTileAction(item)
                                    }} />
                                </Grid>
                            )
                        })
                        : <AppLoader visibal={isLoading} />
                    }
                </Grid> */}
            </div>
            {/* {
                isWebsite && <WebFooter />
            } */}
            <SidePanel title={selectedProduct != null && selectedProduct.name} visible={visibleItemTile} onclose={handleSidePanelClose}>
                {
                    selectedProduct != null 
                    &&
                    <>
                        <div>
                           <CarCarousel images={selectedProduct.images} />
                        </div>
                        <div className='px-15'>
                            <Grid container spacing={0.5}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant='h3' color={config.colors.primary.main} sx={{ fontSize: "25px", fontWeight: "600" }}>{selectedProduct.name}</Typography>
                                    <p style={{color:"#333333", fontWeight:"600", fontSize:"13px", marginTop: "4px"}}>Added No: {selectedProduct.added_on}</p>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant='h6' color={config.colors.black} sx={{ fontSize: "19px", fontWeight: "600" }}>Specifications</Typography>
                                    <br />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    {/* <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "nowrap",
                                        alignContent: "center",
                                        justifyContent: "flex-end",
                                    }}>
                                        <span className='rating-box'>{(parseFloat(selectedProduct.rating).toFixed(1) == "NaN") ? `0.0` : parseFloat(selectedProduct.rating).toFixed(1)}</span>
                                        <Rating SX={{ mt: 1 }}
                                            name="simple-controlled"
                                            value={selectedProduct.rating}
                                            onChange={(event, newValue) => {
                                                // alert(newValue);
                                                setRating(newValue);
                                            }}
                                        />
                                    </div> */}
                                </Grid>
                                {
                                    carSpecsList.map((item, i) => {
                                        return (
                                            <Grid key={i} item xs={12} sm={12}>
                                                <CarSpec type="simple" title={item.title} subtitle={item.subtitle} icon={item.icon} isIcon={true} />
                                            </Grid>
                                        )
                                    })
                                }

                                <Grid item xs={12} sm={12}>
                                    <br />
                                    <Typography variant='h6' color={config.colors.black} sx={{ fontSize: "19px", fontWeight: "600" }}>Description</Typography>
                                    <p style={{fontSize:"14px", color: "#333333"}}>{selectedProduct.description}</p>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <br />
                                    {/* <Typography variant='h6' color={config.colors.black} sx={{ fontSize: "16px", fontWeight: "600" }}>Saler Details</Typography> */}
                                    <Grid container spacing={1} justifyContent='center'>
                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <center>
                                                <Button variant="contained" color="secondary" sx={{borderRadius: "30px", width: "100%"}} onClick={handleCallModalOpen}>Contact Saler</Button>
                                            </center>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <center>
                                                <Button variant="contained" sx={{borderRadius: "30px", background: "#008069", width: "100%"}} onClick={handleWpModalOpen}>WhatsApp</Button>
                                            </center>
                                        </Grid>
                                    </Grid>
                                    <br />
                                </Grid>
                            </Grid>
                            <div style={{
                                marginTop: "20px",
                                background: config.colors.accent.light,
                                borderRadius: "44px",
                                padding: "12px 1px",
                            }}>
                                <Grid container spacing={0} justifyContent="space-around" alignItems="center" alignContent="space-between">
                                    <Grid item xs={6} sm={6} lg={4} md={4}>
                                        <p style={{
                                            fontSize: "25px",
                                            fontWeight: "600",
                                            color: config.colors.black,
                                            margin: "12px 28px"
                                        }}>{selectedProduct.price}</p>
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={4} md={4}>
                                        <Button variant="contained" size='large'
                                            onClick={handlePriceModalOpen}
                                            sx={{ borderRadius: "50px", padding: "12px 22px", fontWeight: "600", textTransform: "capitalize" }}
                                            startIcon={<CurrencyRupeeIcon sx={{

                                            }} />}>Your Price</Button>
                                    </Grid>
                                </Grid>
                            </div>

                            <br />
                            <MyModalWp modalOpen={wpModalOpen} handleModalClose={handleWpModalClose} />
                            <MyModalCall modalOpen={callModalOpen} handleModalClose={handleCallModalClose} />
                            <MyModalPrice product={selectedProduct} modalOpen={priceModalOpen} handleModalClose={handlePriceModalClose} />
                        </div>
                    </>
                }
            </SidePanel>
        </div>
    );
}

const MyModalWp = ({modalOpen = false, handleModalClose = () => {}, ...rest}) => {
    
    const contactLinksWp = [
        { type: "whatsapp", label: "+91 7710 222 444", number: "+917710222444" },
        { type: "whatsapp", label: "+91 7710 222 666", number: "+917710222666" },
        // { type: "whatsapp", label: "+91 7710 177 177", number: "+917710177177" },
        // { type: "whatsapp", label: "+91 9732 777 888", number: "+919732777888" },
    ];
    
    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="budget-modal">
                <ContactOption links={contactLinksWp} />
            </Box>
        </Modal>
    )
}

const MyModalCall = ({modalOpen = false, handleModalClose = () => {}, ...rest}) => {
    const contactLinksCall = [
        { type: "call", label: "+91-7710 222 444", number: "+917710222444" },
        { type: "call", label: "+91-7710 222 666", number: "+917710222666" },
        // { type: "call", label: "+91-7710 177 177", number: "+917710177177" },
        // { type: "call", label: "+91-9732 777 888", number: "+919732777888" },
    ];
    
    
    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="budget-modal">
                <ContactOption links={contactLinksCall} />
            </Box>
        </Modal>
    )
}

const MyModalPrice = ({product = {}, modalOpen = false, handleModalClose = () => {}, ...rest}) => {
    
    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="budget-modal">
                <SendPrice product={product} handleModalClose={handleModalClose} />
            </Box>
        </Modal>
    )
}

const ContactOption = ({links = [], ...rest}) => {
    return (
        <>
            <Typography variant="h5">
                Contact
            </Typography>
            <List dense={true}>
            {
                links.map((item, i) => {
                    return (item.type == 'call')
                        ? (
                            <Fragment key={i}>
                                <Divider />
                                <ListItem
                                    sx={{ padding: "0px 0px" }}
                                >
                                    <ListItemButton
                                        sx={{ padding: "4px 0px" }}
                                        onClick={() => {
                                            window.open(`tel:${item.number}`, '_self');
                                        }}
                                    >
                                        <ListItemAvatar>
                                            <Avatar sx={{ background: config.colors.accent.light }}>
                                                <PhoneInTalkIcon color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={<Typography variant="subtitle2" color={config.colors.black}>
                                            <b>{item.label} </b>
                                        </Typography>} />
                                    </ListItemButton>
                                </ListItem>
                            </Fragment>
                        )
                        : (<Fragment key={i}>
                            <Divider />
                            <ListItem
                                sx={{ padding: "0px 0px" }}
                            >
                                <ListItemButton
                                    sx={{ padding: "4px 0px" }}
                                    onClick={() => {
                                        window.open(`https://api.whatsapp.com/send?phone=${item.number}`, '_self');
                                        // window.open(`https://api.whatsapp.com/send?phone=+91${'9732777888'}&text=${"Hello, I am Satya i just want info about this car!"}`, '_self');
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{ background: "#4caf5024" }}>
                                            <WhatsAppIcon sx={{ color: "#4caf50" }} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={<Typography variant="subtitle2" color={config.colors.black}>
                                        <b>{item.label}</b>
                                    </Typography>} />
                                </ListItemButton>
                            </ListItem>
                        </Fragment>)
                })
            }
            </List>
        </>
    )
}

const SendPrice = ({product = {}, handleModalClose = () => {}, ...rest}) => {
    const [message, setMessage] = useState();
    const [messageErr, setMessageErr] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const handleSendQuotation = async () => {
        if(message == ' ') {
            setMessageErr(true);
        } else {
            setIsLoading(true);
            setMessageErr(false);
            
            const data = {
                message: message,
                product_id: product.id,
            };
            var token = localStorage.getItem('token');
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            await axios.post(`${config.rootUrl}/${config.uris.sendQuotation}`, data)
            .then(({data}) => {
                if (data.status) {
                    setIsLoading(false);
                    alert(data.message);
                    handleModalClose();

                } else {
                    alert(data.message);
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.error(err);
            });
        }
    }
    return (
       <>
        <Typography id="modal-modal-title" variant="h6" component="h2">
            Send Your Price
        </Typography>
        <br />
        <textarea onChange={(e) => setMessage(e.target.value)} rows="5" className="my-textarea" placeholder="Write something ..."></textarea>
        {
            (messageErr) 
            &&
            <FormHelperText sx={{color: config.colors.red}} id="phone-helper">Please enter something!</FormHelperText>
        }
        {
            (isLoading) 
            ?
            <LoadingButton loading variant="outlined">
                Send
            </LoadingButton>
            :
            <Button variant="contained" color="secondary"
                onClick={handleSendQuotation}
                sx={{ marginTop: "10px", width: "100%", color: "#fff", borderRadius: "50px", padding: "8px 22px", fontWeight: "600", textTransform: "capitalize" }}
            >Send
            </Button>
        }
        
       </>
    );
} 

export default Home;